<template>
    <div class="account-content">
        <div class="create-btn">
            <div class="create-title">创建新项目</div>
        </div>
        <div class="new-project">
            <div class="tab-content">
                <div class="card" @click="selectInfo">
                    <div class="mainContain">
                        <div class="pic_box">
                            <img src="../../assets/img/image/media.png" alt="">
                        </div>
                    </div>
                    <div class="container">
                        <div>视频剪辑</div>
                        <div>自由创建视频</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="create-btn">
            <div class="create-title">草稿箱</div>
        </div>
        <div class="sort-list">
            <div class="left-sort">
                <div class="left1" :class="{active:isCreateTime==true}" @click="selectSort(1)">
                    创建时间
                    <span>
                        <i class="iconfont">&#xe65f;</i>
                    </span>
                </div>
                <div class="left2" :class="{active:isUpTime==true}" @click="selectSort(2)">
                    最近修改时间
                    <span>
                        <i class="iconfont">&#xe65f;</i>
                    </span>
                </div>
            </div>
            <div class="right-sort">
                <el-input v-model="captionSearch" size="small" placeholder="输入关键词">
                    <i class="el-icon-search el-input__icon search-btn" slot="suffix" @click="searchHotCaption"></i>
                </el-input>
            </div>
        </div>
        <div class="sort-list">
            <div class="left-sort">
                <div class="left1">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                        全选(已选择{{selectedNum}}个)
                    </el-checkbox>
                </div>
                <div class="left2" @click="deletePro">
                    <i class="iconfont">&#xe638;</i>
                    <span style="padding-left: 2px;">删除</span>
                </div>
            </div>
        </div>
        <div class="res-list">
            <div class="tab-content">
                <div class="card" v-for="item in videoList" :key="item.id">
                    <div class="newContain">
                        <div class="checkbox" style="float: right;">
                            <el-checkbox-group v-model="checkedLists" @change="selectPro">
                                <el-checkbox :label="item.id"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <img @click="showDetail(item)" class="pic-box" :src="item.video_cover.url" alt="">
                    </div>
                    <div class="container">
                        <div>{{item.title}}</div>
                        <div>最后一次更新:{{item.update_time}}</div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
                class="pages-center" background style="padding-bottom: 10px;"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                @current-change="studentCurrentChange">
        </el-pagination>
        <el-dialog
                title="选择项目尺寸"
                :visible.sync="dialogVisible"
                width="40%"
                :before-close="handleClose">
            <el-input v-model="projectName" size="small" placeholder="请输入项目名称"></el-input>
            <div class="mainbox">
                <div :class="{activeClass:isSelect1 == true}" @click="selectSize(1)">
                    <p class="box1">
                        <el-checkbox v-model="isSelect1" v-show="isSelect1 == true"></el-checkbox>
                    </p>
                    <p class="box2">
                        <i class="iconfont">&#xe636;</i>
                    </p>
                    <p class="box3">
                        16:9
                    </p>
                </div>
                <div :class="{activeClass:isSelect2 == true}" @click="selectSize(2)" style="margin: 0 20px;">
                    <p class="box1">
                        <el-checkbox v-model="isSelect2" v-show="isSelect2 == true"></el-checkbox>
                    </p>
                    <p class="box2">
                        <i class="iconfont">&#xe642;</i>
                    </p>
                    <p class="box3">
                        9:16
                    </p>
                </div>
                <div :class="{activeClass:isSelect3 == true}" @click="selectSize(3)">
                    <p class="box1">
                        <el-checkbox v-model="isSelect3" v-show="isSelect3 == true"></el-checkbox>
                    </p>
                    <p class="box2">
                        <i class="iconfont">&#xe636;</i>
                    </p>
                    <p class="box3">
                        1:1
                    </p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="JumpTo">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import _ from 'lodash'
    import videoList from "../mediumModule/videoList";

    export default {
        name: "ShortVideoModule",
        data() {
            return {
                videoList: [],
                videoIdList: [],
                checkedLists: [],
                isCreateTime: false,
                isUpTime: false,
                dialogVisible: false,
                selectedNum: 0,
                checkAll: false,
                isIndeterminate: true,
                shortTable: [],
                currentSort: 0,
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                captionSearch: "",
                projectName: "",
                isSelect1: false,
                isSelect2: false,
                isSelect3: false,
                count1: 0,
                count2: 0,
            }
        },
        mounted() {
            this.getVideoList();
            this.getProjectList();
        },
        methods: {
            selectInfo() {
                this.dialogVisible = true;
            },
            getVideoList(val) {
                let param = {
                    status: 2,
                    title: this.captionSearch,
                    page: this.studentPages.currentPageNum,
                    limit: this.studentPages.eachPageNum,
                }
                if (val) {
                    param.sort = val;
                }
                this.$httpStudent.axiosGetBy(this.$api.videoList, param, res => {
                    if (res.code == 200) {
                        this.videoList = res.data.data;
                        this.videoList.forEach(item => {
                            this.videoIdList.push(item.id)
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            getProjectList() {
                this.$httpStudent.axiosGet(this.$api.describeProjects, res => {
                    if (res.code == 200) {
                        console.log(res, 8888)
                    } else {
                        // this.$message.error(res.message)
                    }
                })
            },
            JumpTo() {
                if (this.projectName == "") {
                    this.$message.warning("请输入标题");
                    return
                }
                if (this.isSelect1 == false && this.isSelect2 == false && this.isSelect3 == false) {
                    this.$message.warning("请选择尺寸");
                    return;
                }
                this.getSign();
            },
            getSign() {
                let param = {
                    name: this.projectName,
                    aspectRatio: this.isSelect1 == true ? "16:9" : this.isSelect2 == true ? "9:16" : "1:1",
                    action: 1,//1创建，2上传
                }
                this.$httpStudent.axiosPost(this.$api.createSign, param, res => {
                    if (res.code == 200) {
                        let sign = res.data.signature;
                        let projectId = res.data.ProjectId;
                        this.$router.push({
                            path: "/student/video/editor",
                            query: {
                                title: this.projectName,
                                size: this.isSelect1 == true ? "16:9" : this.isSelect2 == true ? "9:16" : "1:1",
                                sign: sign,
                                projectId: projectId
                            }
                        })
                        this.handleClose();
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            searchHotCaption() {
                this.getVideoList();
            },
            //全选
            handleCheckAllChange(val) {
                this.checkedLists = val ? this.videoIdList : [];
                this.isIndeterminate = false;
                this.selectedNum = this.checkedLists.length;
            },
            handleClose() {
                this.dialogVisible = false;
                this.projectName = "";
                this.isSelect1 = false;
                this.isSelect2 = false;
                this.isSelect3 = false;
            },
            selectSize(val) {
                this.isSelect1 = val == 1 ? true : false;
                this.isSelect2 = val == 2 ? true : false;
                this.isSelect3 = val == 3 ? true : false;
            },
            studentCurrentChange(val) {
                this.studentPages.currentPageNum = val;
                this.getAccountList()
            },
            //单选
            selectPro(val) {
                let checkedCount = val.length;
                this.checkAll = checkedCount === this.videoList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.videoList.length;
                this.selectedNum = val.length;
            },
            deletePro() {
                if (this.checkedLists.length == 0) {
                    return
                }
                this.$confirm('删除后不能恢复，确定是否删除？', '删除项目', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = this.checkedLists.join(',');
                    this.$httpStudent.axiosGetBy(this.$api.deleteVideo, {id: ids}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.checkedLists = [];
                            this.selectedNum = 0;
                            this.isIndeterminate = true;
                            this.getVideoList();
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            selectSort(val) {
                if (this.count1 > 9 || this.count2 > 9) {
                    this.count1 = this.count2 = 0;
                }
                console.log(this.count1)
                if (val == 1) {
                    this.count1++;
                    this.isCreateTime = true;
                    this.isUpTime = false;
                    let order = this.count1 % 2 == 0 ? 2 : 1;
                    this.getVideoList(order)
                } else {
                    this.count2++;
                    this.isUpTime = true;
                    this.isCreateTime = false;
                    let order = this.count2 % 2 == 0 ? 4 : 3;
                    this.getVideoList(order)
                }
            },
            showDetail(item) {
                let param = {
                    name: item.title,
                    aspectRatio: item.size,
                    // action: 2,//1创建，2上传
                    project_id: item.project_id
                }
                let sign = this.getOpenProjectSign(item.project_id, '100018186395');
                this.$router.push({
                    path: "/student/video/editor",
                    query: {
                        title: item.title,
                        size: item.size,
                        sign: sign,
                        projectId: item.project_id,
                        id: item.id,
                    }
                })
                this.handleClose();
            },
            //申请打开项目签名，官网链接[]
            getOpenProjectSign(projectId, userId) {
                let current = parseInt((new Date()).getTime() / 1000)
                let expired = current + 86400  // 签名有效期：1天

                let args = {
                    secretId: 'AKID1r5fPTcn4QgjhVRVfOjR7dlKCnQKs3hB',
                    currentTimeStamp: current,
                    expireTime: expired,
                    random: Math.round(Math.random() * Math.pow(2, 32)),
                    platform: 'yunjian',
                    projectId: projectId,
                    userId: userId
                }

                return this.genSha1Sign(args)
            },
            genSha1Sign(args) {
                var querystring = require("querystring");
                var crypto = require('crypto');

                let original = querystring.stringify(args)
                let originalBuffer = Buffer.from(original, "utf8")
                let hmac = crypto.createHmac("sha1", 'RgiU9f1JnfNuIcHPrDZXHBqmWXKq512V')
                let hmacBuffer = hmac.update(originalBuffer).digest()
                let signature = Buffer.concat([hmacBuffer, originalBuffer]).toString("base64")
                return signature
            },
            //防抖
            sendAjax: _.debounce(function () {
                this.getVideoList()
            }, 1000),
        },
        watch: {
            captionSearch(d) {
                this.sendAjax();
            }
        },
    }
</script>

<style scoped lang="scss">
    ::v-deep .right-sort .el-input__inner {
        border-radius: 15px;
    }

    ::v-deep .el-dialog__body .el-input__inner {
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        border-bottom-width: 1px;
    }

    ::v-deep .el-dialog {
        border-radius: 10px;
    }

    ::v-deep .el-dialog__footer {
        text-align: center;
    }

    ::v-deep .el-dialog__footer .el-button {
        width: 90px;
        height: 30px;
        line-height: 0;
        border-radius: 15px;
    }

    ::v-deep .el-dialog__footer .el-button--primary {
        background: #594FEE;
    }

    ::v-deep .el-dialog__header {
        background: #F1F0FE;
        border-radius: 10px 10px 0px 0px;
    }

    ::v-deep .el-dialog__title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161240;
    }

    .account-content {
        height: 100%;

        .create-btn {
            padding-bottom: 30px;
        }

        .create-title {
            width: 100px;
            height: 19px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3A3567;
        }

        .res-list {
            min-height: 300px;
        }

        .tab-content {
            display: flex;
            flex-wrap: wrap;

            .card {
                box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.2);
                width: calc(20% - 25px);
                min-width: 250px;
                height: 220px;
                cursor: pointer;
                margin: 0 20px 20px 0;
                background: #EBEBF9;

                .newContain {
                    width: 100%;
                    height: 160px;
                    border-radius: 4px;
                    cursor: pointer;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .checkbox {
                        position: absolute;
                        right: 0;
                        padding: 10px 10px 0 0;

                        ::v-deep .el-checkbox__label {
                            display: none;
                        }
                    }
                }

                .mainContain {
                    width: 100%;
                    height: 160px;
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    background: #16113F;
                    justify-content: center;

                    .pic_box {
                        display: flex;
                        align-items: center;
                    }
                }

                .container {
                    padding: 12px;
                }
            }
        }

        .sort-list {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;

            .left-sort {
                display: flex;

                .active {
                    cursor: pointer;
                    color: #574DED;
                }

                .left1 {
                    cursor: pointer;
                }

                .left2 {
                    padding-left: 30px;
                    cursor: pointer;
                }
            }
        }

        .mainbox {
            display: flex;
            padding-top: 25px;
            justify-content: space-between;
            cursor: pointer;

            .activeClass {
                width: 344px;
                height: 249px;
                background: #FFFFFF;
                border: 3px solid #9ACC5C;
                border-radius: 10px;
            }

            div {
                width: 344px;
                height: 249px;
                background: #FFFFFF;
                border: 3px solid #BCBACC;
                border-radius: 10px;

                .box1 {
                    height: 60px;

                    ::v-deep .el-checkbox {
                        float: right;
                        padding: 10px;
                    }

                    ::v-deep .el-checkbox__inner {
                        width: 51px;
                        height: 51px;
                        background: #9ACC5C;
                        border: 3px solid #9ACC5C;
                        border-radius: 10px;
                    }

                    ::v-deep .el-checkbox__inner::after {
                        height: 30px;
                        left: 13px;
                        width: 15px;
                        border: 3px solid #FFF;
                        border-left: 0;
                        border-top: 0;
                    }
                }

                .box2 {
                    height: 80px;
                    text-align: center;

                    ::v-deep .iconfont {
                        font-size: 80px;
                    }
                }

                .box3 {
                    text-align: center;
                }
            }
        }
    }

</style>